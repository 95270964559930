

























import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    value: {
      type: Number,
      default: undefined,
    },
  },
});












































































































































import { computed, defineComponent, onMounted, onBeforeUnmount, ref, PropType } from '@nuxtjs/composition-api';
import { load } from '~/directives/loading';
import { useAlerts } from '~/features/alerts';
import { useAddCartItem, useCartItemCount } from '~/features/cart';
import { Category } from '~/features/categories';
import { useIsInComparison, removeItem, compareItem, useComparison } from '~/features/compare';
import { useEventBus } from '~/features/events';
import { useI18n } from '~/features/i18n';
import { ProductData } from '~/features/product';
import { TRACKING_EVENTS } from '~/features/trackingHandlers';

export default defineComponent({
  directives: {
    load,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    sku: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isNew: {
      type: Boolean,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    priceBefore: {
      type: Number,
      default: undefined,
    },
    slug: {
      type: String,
      required: true,
    },
    stock: {
      type: Number,
      required: true,
    },
    thumb: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'SimpleProduct',
    },
    highestNumberOfMonths: {
      type: [String, Number],
      required: false,
      value: '',
    },
    smallestAmount: {
      type: [String, Number],
      required: false,
    },
    averageRating: {
      type: Number,
      default: 0,
    },
    bestSeller: {
      type: Boolean,
      default: false,
    },
    compareItem: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Array as PropType<Array<Category>>,
      required: true,
    },
    product: {
      type: Object as PropType<ProductData>,
      required: false,
    },
    customLabel: {
      type: String,
      default: '',
    },
    customLabel2: {
      type: String,
      default: '',
    },
    isAC: {
      type: Number,
      default: 0,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    withCheckButton: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    seller: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { emit: emitEvent } = useEventBus();

    const el = ref<Element>();
    const observer = ref<IntersectionObserver>();
    const { itemId } = useCartItemCount(props.sku);

    const showRayaPlusIcon = computed(() => {
      return ['rayashop@rayashop.com', 'admin@rayashop.com', 'rayashopmobile@gmail.com'].includes(props.seller);
    });

    const isLimitedStock = computed(() => {
      return false;
    });

    const discount = computed(() => {
      if (!props.priceBefore) {
        return 0;
      }

      return (props.priceBefore - props.price) / props.priceBefore;
    });

    const isGrouped = computed(() => {
      return props.type === 'GroupedProduct';
    });
    const rating = computed(() => {
      return (props.averageRating / 5) * 100;
    });

    const isInComparison = useIsInComparison(props.sku);

    onMounted(() => {
      // Send product impression event only if card is above the fold
      observer.value = new IntersectionObserver(
        entries => {
          if (entries[0].intersectionRatio === 1) {
            emitEvent(TRACKING_EVENTS.ON_PRODUCT, { ...props });
            observer.value?.disconnect();
          }
        },
        {
          threshold: [1.0],
        }
      );

      observer.value.observe(el.value as Element);
    });

    onBeforeUnmount(() => {
      observer.value?.disconnect();
    });

    /**
     * Function that handles triggering product_click event for Enhanced Ecommerce
     */
    function productClickEvent() {
      emitEvent(TRACKING_EVENTS.PRODUCT_CLICK, { ...props });
    }
    const { products } = useComparison();
    const { t } = useI18n();
    const { error, success } = useAlerts();
    const { addItem } = useAddCartItem();
    const isAddingItem = ref(false);
    async function toggleComparison() {
      if (isInComparison.value) {
        removeItem(props.sku, props.product);
        return;
      }

      if (products.value.length >= 4) {
        error('', t('comparisonExceeded').toString());
        return;
      }

      try {
        await compareItem({
          sku: props.sku,
          categoryIds: props.categories.reduce<number[]>(
            (accu, category) => (category && category.id ? [...accu, category.id] : accu),
            []
          ),
        });
      } catch (e) {
        error('', t((e as Error).message).toString());
      }
    }

    async function addToCart() {
      isAddingItem.value = true;
      try {
        // Handles configurable and simple products
        await addItem({
          sku: props.sku as string,
          quantity: 1,
        });
        success('Item added to cart successfully');
      } catch (err: any) {
        // bypass the generic cart error
        // it only means other items in the cart are out of stock but the item
        // was added successfully
        if (/Some of the products are out of stock./i.test(err.message)) {
          success('Item added to cart successfully');
        }
        if (/the requested qty is not available/i.test(err.message) || /The requested qty exceeds/i.test(err.message)) {
          error(t('stockError') as string, t('qtyUnavailable') as string);
          return;
        }

        if (/Product that you are trying to add is not available/i.test(err.message)) {
          error(t('outOfStockError', [props.name]) as string);
        }
      } finally {
        isAddingItem.value = false;
      }
    }

    /**
     * handles whether or not a given label should be displayed based on the labels priorities
     * only two labels should be displayed at any given point in time
     * prioties:
     * custom label 1
     * custom label 2
     * % off
     * bestseller
     */
    function isLabelValid(label: string) {
      switch (label) {
        case 'customLabel':
          return true;
        case 'customLabel2':
          return true;
        case 'offer':
          if (props.customLabel && props.customLabel2) {
            return false;
          }
          return true;
        case 'bestSeller':
          if (
            (props.customLabel && props.customLabel2) ||
            (props.customLabel && discount.value) ||
            (props.customLabel2 && discount.value)
          ) {
            return false;
          }
          return true;
        default:
          return false;
      }
    }

    function handleProductCheck(productId: number) {
      emit('productCheck', productId);
    }

    function handleProductUncheck(productId: number) {
      emit('productUncheck', productId);
    }

    return {
      isLimitedStock,
      discount,
      isInComparison,
      toggleComparison,
      productClickEvent,
      isGrouped,
      el,
      rating,
      addToCart,
      isAddingItem,
      cartItemId: itemId,
      isLabelValid,
      handleProductCheck,
      handleProductUncheck,
      showRayaPlusIcon,
    };
  },
});

import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=6b9d478d&scoped=true&"
import script from "./ProductCard.vue?vue&type=script&lang=ts&"
export * from "./ProductCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProductCard.vue?vue&type=style&index=0&id=6b9d478d&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b9d478d",
  null
  
)

/* custom blocks */
import block0 from "./ProductCard.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FavoriteButton: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/FavoriteButton.vue').default,ProductCheckButton: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/ProductCheckButton.vue').default,AppLink: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/AppLink.js').default,ProductRating: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/ProductRating.vue').default,Money: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/Money.vue').default,AppButtonV2: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/AppButtonV2.vue').default})

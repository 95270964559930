
















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductCheckButton',
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    function toggle() {
      if (props.isChecked) {
        emit('productUncheck', props.productId);
      } else {
        emit('productCheck', props.productId);
      }
    }

    return {
      toggle,
    };
  },
});

import { render, staticRenderFns } from "./ProductCheckButton.vue?vue&type=template&id=706b8726&scoped=true&"
import script from "./ProductCheckButton.vue?vue&type=script&lang=ts&"
export * from "./ProductCheckButton.vue?vue&type=script&lang=ts&"
import style0 from "./ProductCheckButton.vue?vue&type=style&index=0&id=706b8726&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "706b8726",
  null
  
)

export default component.exports